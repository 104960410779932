import React from 'react';
import Header from '../components/Header';
import Layout from '../components/layout';
import Adbutler from '../components/Adbutler';

const TyGCP = () => {
  // sets adbutler zone id's based on param data
  let adButlerIds = ['290064', '290065', '290066', '290067', '290068'];

  return (
    <>
      <Header />
      <Layout isfluid={false}>
        <Adbutler adButlerIds={adButlerIds} />
      </Layout>
    </>
  );
};

export default TyGCP;
